<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Transaction List</h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button>
      </div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="transactions"
        @row-clicked="showDetails"
      >
        <template #cell(staff)="data">
          {{ data.value.first_name }} {{ data.value.last_name }}
        </template>
      </b-table>
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
      size="lg"
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="transactionForm">
          <b-form @submit.prevent="is_update ? updateTransaction() : createTransaction()">
            <b-row>
              <b-col
                cols="6"
                class="t-date"
              >
                <b-form-group
                  label="Transaction ID"
                  label-for="h-transaction-id"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="transaction_id"
                    rules="required"
                  >

                    <b-form-input
                      id="v-transaction-id"
                      v-model="transaction.transaction_number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="#### ## ##"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                class="t-date"
              >
                <b-form-group
                  label="Transaction Date"
                  label-for="h-transaction-date"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="transaction_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="h-transaction-date"
                      v-model="transaction.transaction_date"
                      class="mb-1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Business"
                  label-for="h-name"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="business"
                    rules="required"
                  >
                    <v-select
                      v-model="business"
                      label="name"
                      :filterable="false"
                      :options="options"
                      :state="errors.length > 0 ? false : null"
                      @search="onSearch"
                    >
                      <template slot="no-options">
                        type to search business
                      </template>
                      <template
                        slot="option"
                        slot-scope="option"
                      >
                        <div class="d-center">
                          <!-- <img :src="option.owner.avatar_url"> -->
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                class="t-date"
              >
                <b-form-group
                  label="Start Date"
                  label-for="h-start-date"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="h-start-date"
                      v-model="transaction.start_date"
                      class="mb-1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                class="t-date"
              >
                <b-form-group
                  label="End Date"
                  label-for="h-end-date"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="h-end-date"
                      v-model="transaction.end_date"
                      class="mb-1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <h4 class="mt-2 mb-1">
                    Add State
                  </h4>
                <div class="row">
                  <div
                    v-for="(item, index) in this.authorized_states"
                    :key="index"
                    class="col-md-12"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <b-form-group
                          label="State"
                          label-for="h-state"
                          label-cols-md="12"
                        >

                          <b-form-select
                            id="h-state"
                            v-model="item.state_id"
                            :options="state_options"
                          />

                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          v-b-tooltip.hover.top="'Delete'"
                          variant="outline-danger"
                          style="margin-top:35px;"
                          @click="removeInput(index)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12 mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-tooltip.hover.top="'Add new service area'"
                      variant="outline-primary"
                      @click="addInput"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </div>
                </div>

              </b-col>
            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
              >
                Save
              </b-button>
            </div>
          </b-form>

        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormDatepicker,
  VBTooltip,
  BFormSelect,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { notifyMe } from '@/libs/toast'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      is_update: false,
      required,
      options: [],
      business: null,
      fields: [{ key: 'transaction_number', label: 'Transaction Number', sortable: true }, { key: 'transaction_date', label: 'Transaction Date', sortable: true }, { key: 'business_profile.name', label: 'Business Name', sortable: true }, { key: 'start_date', label: 'Start Date', sortable: true }, { key: 'end_date', label: 'End Date', sortable: true }, { key: 'staff', label: 'created_by', sortable: true }],
      transaction: {
        business_profile_id: null,
        transaction_number: null,
        transaction_date: null,
        start_date: null,
        end_date: null,
      },
      authorized_states: [],
    }
  },
  computed: {
    // Get the transactions from the Vuex store state
    transactions() {
      return this.$store.state.transaction.transactions
    },

    // Calculate the total number of rows in the 'all_transaction' array
    totalRows() {
      return this.all_transaction.length
    },

    // Get the user data from the Vuex store state
    userData() {
      return this.$store.state.user.profile
    },

    // Get the states from the Vuex store state
    states() {
      return this.$store.state.state.states
    },

    // Map the 'states' array to create an array of options for a select input
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },

  },
  mounted() {
    // Invoke the getTransaction() method to retrieve the updated transaction data
    this.getTransaction()
  },
  methods: {
    // Show the modal by invoking the show method of the BootstrapVue Modal plugin
    showModal() {
      this.$bvModal.show('app-modal')
    },

    // Asynchronously dispatch the 'getTransactions' action to retrieve transaction data
    async getTransaction() {
      await this.$store.dispatch('transaction/getTransactions')
    },
    addInput() {
      // Add a new object to the 'autorize_state' array in the 'transaction' object
      this.authorized_states.push(
        { state_id: null, id: null },
      )
    },
    async removeInput(index) {
      const item = this.authorized_states[index]
      if (item.state_id !== null) {
        await this.$store.dispatch('transaction/deleteAuthorizedState', item.id)
        const dom = this
        setTimeout(() => {
          dom.getTransaction()
        }, 300)
      }
      // Remove the element at the specified index from the 'autorize_state' array in the 'transaction' object
      this.authorized_states.splice(index, 1)
      if (this.authorized_states.length < 1) {
        this.hide()
      }
    },
    async createTransaction() {
      // Validate the transaction form using the $refs and execute logic if validation is successful
      this.$refs.transactionForm.validate().then(async success => {
        if (success) {
          const data = this.transaction

          // Assign business_profile_id and staff_id based on respective values
          data.business_profile_id = this.business.id
          data.staff_id = this.userData.id
          data.authorized_states = this.authorized_states.map(elm => elm.state_id)
          // Dispatch an action to create a new transaction using the transaction data
          await this.$store.dispatch('transaction/createTransaction', data)

          // Hide the transaction form
          this.hide()

          // Get the updated transaction data after a delay of 300 milliseconds
          const dom = this
          setTimeout(() => {
            dom.getTransaction()
          }, 300)
        }
      })
    },
    async updateTransaction() {
      // Validate the transaction form and execute logic if validation is successful
      this.$refs.transactionForm.validate().then(async success => {
        if (success) {
          const data = this.transaction

          // Assign business_profile_id and staff_id based on respective values
          data.business_profile_id = this.business.id
          data.staff_id = this.userData.id
          data.authorized_states = this.authorized_states.map(elm => elm.state_id)
          // Delete unnecessary properties from the data object
          delete data.business_profile
          delete data.staff

          // Dispatch the 'updateTransaction' action with the updated data
          await this.$store.dispatch('transaction/updateTransaction', data)

          // Hide the form and retrieve the updated transaction data after a delay of 300 milliseconds
          this.hide()
          const dom = this
          setTimeout(() => {
            dom.getTransaction()
          }, 300)
        }
      })
    },

    emptyData() {
      // Set the transaction object to empty values after a delay
      const dom = this
      setTimeout(() => {
        dom.transaction = {
          transaction_number: null,
          business_profile_id: null,
          transaction_date: null,
          start_date: null,
          end_date: null,
        }
        dom.authorized_states = []
      })
    },

    hide() {
      // Set 'is_update' to false, hide the modal, and reset the data
      this.is_update = false
      this.$bvModal.hide('app-modal')
      this.emptyData()
    },

    showDetails(item) {
      // Set 'is_update' to true, assign the transaction and business values, and show the modal
      this.is_update = true
      this.transaction = item
      this.business = item.business_profile
      this.authorized_states = item.authorized_states.map(stateItem => ({ state_id: stateItem.state.id, id: stateItem.id }))
      this.showModal()
    },

    async deleteData() {
      // Prompt the user for confirmation using swal and execute logic based on the result
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          // Dispatch the 'deleteTransaction' action with the transaction id, hide the modal, and retrieve the updated transaction data after a delay of 300 milliseconds
          await this.$store.dispatch('transaction/deleteTransaction', this.transaction.id)
          this.hide()
          const dom = this
          setTimeout(() => {
            dom.getTransaction()
          }, 300)
        }
      })
    },

    onSearch(search, loading) {
      // Perform a search operation if the search term is not empty
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },

    search: _.debounce((loading, search, vm) => {
      // Perform a debounced search using the provided search term and update options based on the response
      window.cxSocketApi.talk('get-admin-business-profiles', response => {
        vm.options = response.data.data
        loading(false)
      }, error => notifyMe(error.data, 'danger'), { search_term: search, page: 1, per_page: 100 })
    }, 100),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
